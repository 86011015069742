
import React from 'react';
import '../css/VideoSection.css'; // Import your CSS file

export default function Reviews() {
  
  return (
    <div className="serviceRealBackground"> <h2 className="serviceTitle">Reviews</h2> 
    <script src="https://embedsocial.com/js/iframe.js"></script><iframe class = "reviewStyle" scrolling="no" src="https://embedsocial.com/api/pro_hashtag/f429446176ba58c3af1e8dea8528a9c3fb8ad164"></iframe><script>iFrameResize();</script>
    </div>
  );
}