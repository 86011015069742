import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import AboutMe from './AboutMe.js';
import Schedule from './Schedule.js';
import Photos from './Photos.js';
import WishList from './WishList.js';
import Questions from './Questions.js';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "",
    element: <App />,
  },
  {
    path: "home",
    element: <App />,
  },
  {
    path: "aboutus",
    element: <AboutMe />,
  },
  {
    path: "schedule",
    element: <Schedule />,
  },
  {
    path: "photos",
    element: <Photos />,
  },
  {
    path: "questions",
    element: <Questions />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <RouterProvider router={router} />
   
  
);


