import './css/App.css';
import NavBar from './homeComponents/NavBar.js';
import Promo from './homeComponents/Promo';
import VideoSection from './homeComponents/VideoSection.js';
import HomeBackground from './homeComponents/HomeBackground.js';
import ServiceReal from './homeComponents/Services';
import Contact from './homeComponents/Contact';
import Footer from './homeComponents/Footer';
import NewService from './homeComponents/NewService';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NewHomeBackground from './homeComponents/NewHomeBackground';
import PromoSticky from './homeComponents/PromoSticky';
import Reviews from './homeComponents/Reviews.js';

function App() {
  return (
    <div>
      
      <Promo />
      <PromoSticky />
      <NavBar />
      <VideoSection />
      <NewHomeBackground />
      <NewService />
      <Reviews />
      <Contact />
      <Footer />
      
    </div>
  );
}


export default App;


