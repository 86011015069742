import React from 'react';
import '../css/App.css'
import bubspups from '../css/images/bubspups.png'
import AboutMe from '../AboutMe';
import { Link } from "react-router-dom";

export default class NavBar extends React.Component {
  render() {
    return (
      <nav className="navbar" style={{ backgroundColor: '#3A1F89' }}>
        <div className="logo">
          <Link to="/home">
          <img className = "logo" src = {bubspups} alt = "Logo" />
          </Link>
          
        </div>
        <input type="checkbox" id="toggle" className="toggle" />
        <label htmlFor="toggle" className="toggle-label">
          <span></span>
          <span></span>
          <span></span>
        </label>
        <ul className="nav-links">
          <li className="nav-item">
            <Link to="/home">Home</Link>
          </li>
          <li className="nav-item">
            <Link to="/aboutus">About Us</Link>
          </li>
          <li className="nav-item">
            <Link to="/schedule">Schedule & Pay Ahead</Link>
          </li>
          <li className="nav-item">
            <Link to="/photos">
              Photos</Link>
          </li>
        </ul>
      </nav>
    );
  }
}

