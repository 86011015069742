import '../css/VideoSection.css';


export default function QuestionService()
{
    return(
        <div className = "questionBackground">
        
        <h2 className = "questionsTitle">Questions and Liability Forms</h2>
        <p className = "questionsDescription">New customers must fill out both forms.</p>
            
        <a href={"https://docs.google.com/forms/d/1O1iSdFtduvO75DFtPYoyJh2Ida3808df9tr73vnr_Gg"} target='_blank'>
            <input className = "payButton" type = "button" value="Proceed to Questions"></input>
        </a>
        <a href={"https://form.jotform.com/232430711877052"} target='_blank'>
            <input className = "payButton" type = "button" value="Proceed to Liability Form"></input>
        </a>
        </div>
    );
}