import '../css/VideoSection.css'
import logo from '../css/images/bubspups.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faGoogle, faInstagram, faYelp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';


export default function Footer() {
  return (
    <div className = "footerBackground">
        <div className = "FooterContainer">
          <div className = "FooterWrapper">
            <div className = "FooterBox">
              <h2 className = "FooterContactTitle"> Check out our socials and rate your experience! </h2>
              <p className = "FooterLogoTextIcon"><Link target = "_blank" to= "https://www.google.com/search?q=bubs+pups+alaska&oq=bubs+pups+alaska&aqs=chrome..69i57j35i39j69i59j69i60l3.3201j0j7&sourceid=chrome&ie=UTF-8" className = "TextDecoration"><FontAwesomeIcon  icon={faGoogle} size="sm" /> "Bub's Pups Alaska"</Link>
              <br></br><br></br><FontAwesomeIcon  icon={faInstagram} size="sm" /> @bubspups907<br></br><br></br>
              <Link className = "TextDecoration"  target= "_blank" to = "https://www.yelp.com/biz/bubs-pups-sterling"><FontAwesomeIcon  icon={faYelp}  /> Bub's Pups</Link></p>
              <p className = "FooterLogoText"> <br></br>If your not satisfied, we're not satisfied!</p>
            </div>
            <div className = "FooterBox">
              <img className = "footerLogo" src={logo}></img>
              <p className = "FooterLogoText">"Our dog daycare facility is a haven for your furry friends, offering a unique and unparalleled experience that combines top-notch care with the breathtaking essence of the Last Frontier. We take care of dogs within the region of Soldotna, Kenai, and Sterling."</p>
            </div>
            <div className = "FooterBox">
              <h2 className ="FooterContactTitle">Contact Information</h2>
              
              <p className = "FooterContactText"><FontAwesomeIcon  icon={faPhone} size="sm" /> 480-826-7345<br></br><br></br><FontAwesomeIcon  icon={faEnvelope} size="sm" /> bubspups907@gmail.com<br></br><br></br><FontAwesomeIcon  icon={faLocationDot} size="sm" /> 37094 Lou Morgan Road, Sterling, Alaska 99672<br></br><br></br><FontAwesomeIcon  icon={faClock} size="sm" /> Hours: 9am - 5pm</p>
              
            </div>
          </div>
        </div>
        
    </div>
  );
}