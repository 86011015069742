import '../css/VideoSection.css'
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JotformEmbed from 'react-jotform-embed';

export default function Contact() {
  return (
    <div className = "contactBackground">
        
        <h2 className = "contactTitle">Contact Us Today <FontAwesomeIcon  icon={faComment} size="sm" /></h2>

        <div className = "contactContainer">
          <div className = "contactWrapper">
            <div className = "contactBox">
              <p className = "scheduleText">Enter your name and email and we will contact you <br></br> within 24 hours!</p>
              <div className ="CoverWatermark"></div>
               <JotformEmbed className="adjustBorder" src="https://form.jotform.com/231938971933065"/>
            </div>
            <div className = "contactBox">
              <iframe className="mapStyle" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7854.841274246815!2d-150.847483!3d60.516007!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x56c66760869be3df%3A0xaf185f76fd5356f9!2sBub&#39;s%20Pups!5e0!3m2!1sen!2sus!4v1689114819897!5m2!1sen!2sus"></iframe>
            </div>
          </div>
        </div>
    </div>
          
  );
}