
import '../css/VideoSection.css';
import dogImage from '../css/images/dogPhotoResize.jpg'
import dog1 from '../css/images/IMG-6846.jpg';
import dog2 from '../css/images/IMG-6853.jpg';
import dog3 from '../css/images/IMG-6854.jpg';
import dog4 from '../css/images/IMG-6856.jpg';
import dog5 from '../css/images/img.jpg';
import dog6 from '../css/images/dog1.jpg';
import dog7 from '../css/images/dog2.jpg';
import dog8 from '../css/images/dog3.jpg';
import dog9 from '../css/images/IMG-6736.jpg';
import dog10 from '../css/images/IMG-6801.jpg';
import dog11 from '../css/images/IMG-6826.jpg';
import dog12 from '../css/images/IMG-6830.jpg';
import dog13 from '../css/images/IMG-6892.jpg';
import dog14 from '../css/images/IMG-6894.jpg';
import dog15 from '../css/images/IMG-6915.jpg';
import dog16 from '../css/images/IMG-7042.jpg';
import dog17 from '../css/images/IMG-7069.jpg';
import dog18 from '../css/images/IMG-7100.jpg';
import dog19 from '../css/images/IMG-7124.jpg';
import dog20 from '../css/images/IMG-7129.jpg';
import dog21 from '../css/images/IMG902.jpg';
import dog22 from '../css/images/Resized_20230907_094040.JPEG'
import dog23 from '../css/images/Resized_20230907_111149.JPEG'
import dog24 from '../css/images/Resized_20230908_092901.JPEG'
import dog25 from '../css/images/Resized_20230908_170007.JPEG'
import dog26 from '../css/images/Resized_20230913_122134.JPEG'
import dog27 from '../css/images/Resized_20230915_113549.JPEG'
import dog28 from '../css/images/Resized_20230927_071410.JPEG'
import dog29 from '../css/images/Resized_20231003_173552.JPEG'
import dog30 from '../css/images/Resized_20231006_140011(0).JPEG'
import dog31 from '../css/images/Resized_20231010_134304.JPEG'
import dog32 from '../css/images/Resized_20231024_135545.JPEG'
import dog33 from '../css/images/Resized_20231126_160518.JPEG'
import dog34 from '../css/images/Resized_20231127_081404.JPEG'
import dog35 from '../css/images/Resized_20240106_131909.JPEG'
import dog36 from '../css/images/Resized_20240108_131813.JPEG'
import dog37 from '../css/images/Resized_20240110_130209.JPEG'
import dog38 from '../css/images/Resized_Resized_20230908_170021.JPEG'
import dog39 from '../css/images/Attachment (1).JPG'
import dog40 from '../css/images/Attachment (2).JPG'
import dog41 from '../css/images/Attachment (3).JPG'
import dog42 from '../css/images/Attachment.JPG'

export default function PhotoComp()
{
    return(
        <div className = "PhotoBackground">
            <h2 className = "PhotoTitle">Photos</h2>
            <p className = "PhotoText">We add more weekly!</p>
          <div className= "PhotoContainer">
            <div className = "PhotoWrapper">
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog1} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog2} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog3} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog4} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog5} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog6} />
                    
                </div>
                 <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog7} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog8} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog9} />
                    
                </div>
                 <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog10} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog11} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog12} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog13} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog14} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog15} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog16} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog17} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog18} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog19} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog20} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog21} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog22} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog23} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog24} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog25} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog26} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog27} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog28} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog29} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog30} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog31} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog32} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog33} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog34} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog35} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog36} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog37} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog38} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog39} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog40} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog41} />
                    
                </div>
                <div className = "Photos">
                    
                    <img className = "PhotoResize" src={dog42} />
                    
                </div>
            </div>
          </div>
        </div>
    );
}