import './css/VideoSection.css'
import NavBar from './homeComponents/NavBar';
import Footer from './homeComponents/Footer';
import ComingSoon from './comingSoonComponent/ComingSoon';
import PhotoComp from './photoComponents/PhotoComp';
import { useLayoutEffect } from 'react';

export default function Photos() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <div>
        <NavBar />
        <PhotoComp />
        <Footer />
    </div>
  );
}