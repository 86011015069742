import { Link } from 'react-router-dom';
import '../css/VideoSection.css';
import ScheduleComp from './ScheduleComp';
import ScheduleCompTwo from './ScheduleCompTwo';


export default function PayAhead()
{
    return(
        <div className = "scheduleBackground">
            <h1 className = "scheduleTitle">Schedule Dropoffs/Pickups & Pay Ahead</h1>
            <p className="scheduleText">To schedule a drop off and pick up, you must enter your dates on the two separate calendars below. <br></br> Additionally, there is a "Pay Ahead" Option below these calendars in case you would like to pay with your debit/credit card ahead of time.<br></br>Please Note: We also take card in-person.<br></br><br></br>Rates are $40/per dog a day (includes day of pickup and dropoff)</p>
            <h1 className = "scheduleTitle">Schedule Dropoffs</h1>
            <ScheduleComp />
            <h1 className = "scheduleTitle">Schedule Pickups</h1>
            <ScheduleCompTwo />
            <h1 className = "scheduleTitle">Pay Ahead</h1>
            <Link to="https://square.link/u/Buctd5SK" target = "_blank">
            <div className = "paySection">
            
            <input className = "payButton" type="button" value ="Advance to Payment Site"></input>
            
            </div>
            </Link>
            
        </div>
    );
}