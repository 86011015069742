import '../css/VideoSection.css';
import dogimage from '../css/images/andrewDog.png';
import Justin from '../css/images/Justin_Employee.png'
import Makayla from '../css/images/Makayla_Employee.jpg'

export default function AboutMain()
{
    return(
        <div className = "aboutBackground">
            <img src = {dogimage} className = "andrewDog" />
            <h1 className = "aboutTitle">About Us</h1>
            <h1 className = "aboutTitle">Andrew - Owner</h1>
            <p className = "aboutText">Andrew, a devoted dog owner, has exemplified exceptional care and love for his dog during his time in Arizona. For over ten years, Andrew has been a dedicated guardian, ensuring his pet experiences the utmost comfort, health, and happiness. Andrew has consistently prioritized the well-being of his dog through daily exercise and balanced nutrition, which has provided her with a nurturing and fulfilling life in Arizona.</p>
            <p className = "aboutText">Andrew embarked on a remarkable journey as he relocated with his dog to the landscapes of Alaska! Motivated by his unwavering dedication to the well-being of dogs, Andrew's move to Alaska ignited a newfound passion within him: to open a dog daycare center that reflects the stunning Alaskan atmosphere and provides the highest quality of care. Drawing on his years of experience in caring for his own dog and working at a dog resort, Andrew envisions a daycare facility that goes above and beyond, offering a location where Alaskan canines can thrive.

Andrew's vision for the dog daycare center is rooted in his desire to create a safe and stimulating environment where dogs can socialize, exercise, and receive individualized attention. He aims to establish a facility that embraces the spirit of Alaska, with several play areas designed to resemble the rugged wilderness and cozy rest areas. By incorporating several surroundings into the daycare center, Andrew seeks to provide dogs with an immersive and enriching experience that aligns with their natural instincts and fosters their overall well-being!</p>
         <div class ="aboutWrapper">
            <div class = "EmployeePhoto">
            <h1 className = "aboutTitle">Justin - Staff</h1>
            <img src = {Justin} class="PhotoOf" />
            </div>
            <div className="EmployeePhoto">
            <h1 className = "aboutTitle">Makayla - Staff</h1>
                <img src = {Makayla} class="PhotoOf"  />
            </div>

            
            
        </div>
        </div>
    );
}