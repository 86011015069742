import '../css/VideoSection.css'
import dogvid from "../videos/vecte.mp4"
import { Link } from "react-router-dom";
import dogimg from "../css/images/DognewImage.jpg"


export default function VideoSection() {
  return (
  <div className = "backgroundVid">
    <div className = "backgroundofText">
    <h1 className = "backgroundText">Unleash the Paws-abilties at Bub's Pups in Sterling!</h1>
    <Link to="/schedule">
    <input className = "homeButton" type = "button" value="Schedule today"></input>
    </Link>
    </div>
  <div className="background-video">
      
      <img src={dogimg} className="video">
      </img>
      
  
    </div>
    </div>
  );
}