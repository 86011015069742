import '../css/VideoSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw } from '@fortawesome/free-solid-svg-icons';




export default function NewService() {
  return (
    <div className = "serviceNewBackground">
      <h2 className = "serviceTitle">Our Services Are...</h2>
      <div className = "serviceContainer">
        <div className = "serviceWrapper">
          <div className = "serviceBox">
            <div className = "PawContainer">
            <FontAwesomeIcon className = "PawIcon" icon={faPaw} size="lg" />
            </div>
          </div>
          <div className = "serviceBox">
            <div className = "serviceBoxContainer">
              <p className = "serviceBoxNumber">1</p>
              <p className = "serviceBoxTitle">Dog Sitting</p>
            </div>
            <p className = "serviceBoxDescription">Are you planning a day trip or just need someone to care for your dog while you are at work? Look no further! Bub’s Pups is here to help! We offer excellent daycare services so your dogs won’t have to be home alone. To schedule a stay at Bub’s Pups or just have some questions, please give us a call!</p>
          </div>
          <div className = "serviceBox">
            <div className = "serviceBoxContainer">
              <p className = "serviceBoxNumber">2</p>
              <p className = "serviceBoxTitle">Dog Boarding</p>
              
            </div>
            <p className = "serviceBoxDescription">If you need your dog to stay overnight or for an extended period, at Bub’s Pups we also offer dog boarding. To schedule an extended stay for your dog(s), give us a call today!
</p>
          </div>
          <div className = "serviceBox">
            <div className = "serviceBoxContainer">
              <p className = "serviceBoxNumber">3</p>
              <p className = "serviceBoxTitle">Care + Exercise</p>
              
            </div>
            <p className = "serviceBoxDescription">Whether you are dropping your dog(s) off for one day or boarding them for a longer time, at Bub’s Pups we are very intentional about ensuring every dog is offered food and water, daily walks, and plenty of love and attention throughout the day. We will follow any specific food-related instructions for your dog if they are on a special diet. 
</p>
          </div>
          <div className = "serviceBox">
            <div className = "serviceBoxContainer">
              <p className = "serviceBoxNumber">4</p>
              <p className = "serviceBoxTitle">Transportation</p>
              
            </div>
            <p className = "serviceBoxDescription">We offer transportation to or from Bub’s Pups as long as the pick up or drop off location is within 20 miles of our location. *Fee may vary based on pick up location. </p>
          </div>
          <div className = "serviceBox">
            <div className = "PawContainer">
            <FontAwesomeIcon className = "PawIcon" icon={faPaw} size="lg" />
            </div>
          </div>
        </div>
      </div>
      <p className = "serviceRealTextAlt">Rates are $40/per dog a day (includes day of pickup and dropoff). If your pet boards with us for more <br/> than two weeks, a lower price may be applied. We also send lots of pictures/videos of your pet during the day!</p>
    </div>
  
  
  );
}