import '../css/VideoSection.css';
import bubspupsimage from '../css/images/bubspupsimage.jpg'
import bubspupscrate from '../css/images/collage-downsized.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { Link } from "react-router-dom";
import { faBone } from '@fortawesome/free-solid-svg-icons';

export default function HomeSection() {
  return (
    
  <div className = "HomeContainer">
    <div className = "HomeWrapper">
        <div class="HomeBox">
            <img className = "HomeBoxImage" src = {bubspupscrate} alt="Our Dogs"></img>
        </div>
        <div class="HomeBox">
          <h2 className = "HomeBoxTitleOne">Experience Quality Care <br></br> For Your Pet <FontAwesomeIcon  icon={faCheckSquare} size="sm" /></h2>
          <p className = "HomeBoxTextOne"><br></br>Welcome to Alaska's premier dog daycare facility, where quality care meets the majestic Alaskan atmosphere. Our dog daycare facility is a haven for your furry friends, offering a unique and unparalleled experience that combines top-notch care with the breathtaking essence of the Last Frontier.<br></br><br></br>Our team is passionate about providing the highest standard of care for every dog entrusted to us. With years of experience and an understanding of canine behavior, we ensure that each dog receives personalized attention, tailored to their unique needs and preferences.</p>
          <Link to="/aboutus">
          <input className = "aboutButton" type = "button" value="About Us"></input>
          </Link>
        </div>
        <div class="HomeBox HomeBoxBackground">
          <h2 className = "HomeBoxTitleTwo">Unleash The Adventure <FontAwesomeIcon  icon={faBone} size="sm" /></h2>
          <p className = "HomeBoxTextTwo"><br></br>We prioritize the well-being and happiness of our furry guests, offering a range of enriching activities to keep them engaged and entertained throughout their stay. From nature walks to interactive play sessions in our spacious, specially designed play areas, every moment at our daycare facility is an adventure filled with joy and excitement. <br></br><br></br> Whether you are a local Alaskan resident or visiting our magnificent state, our dog daycare welcomes dogs of all breeds and sizes, providing a warm and nurturing environment where they can flourish. Come and join us in celebrating the spirit of Alaska while entrusting your furry companion to the highest quality of care. </p>
          <Link to="/photos">
          <input className = "HomePhotoButton" type = "button" value="Photos"></input>
          </Link>
        </div>
        <div class="HomeBox">
          <img className = "HomeBoxImage" src = {bubspupsimage} alt="Our Location Landscape Photo"></img>
        </div>
    </div>
  </div>
  
  
  );
}

