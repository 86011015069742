import './css/VideoSection.css'
import NavBar from './homeComponents/NavBar';
import Footer from './homeComponents/Footer';
import AboutMain from './aboutUsComponents/AboutMain';
import { Component } from 'react';
import { useLayoutEffect } from 'react';

export default function AboutMe() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (
    <div>
        <NavBar />
        <AboutMain />
        <Footer />
    </div>
  );
}